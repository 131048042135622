import { graphqlClient } from "@/apollo";
import {
  DeleteNewsDocument,
  GetNewsByFiltersDocument,
  NewsEntryDocument,
  type NewsInput,
  SaveNewsDocument,
} from "@/graphql/generated-types";

type VisibilityOption = "visibility-on" | "visibility-off";

const getNews = async (
  dateFrom: string,
  dateTo: string,
  visibility: VisibilityOption,
) => {
  const response = await graphqlClient.query({
    query: GetNewsByFiltersDocument,
    variables: {
      visibility: visibility === "visibility-on",
      dateFrom,
      dateTo,
      filter: {
        departments: [],
        groups: [],
        institutions: [],
      },
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.newsItem?.getNewsByFilters) {
    return response.data.newsItem.getNewsByFilters;
  }

  throw new Error("Query error");
};

const getNewsDetail = async (id: string) => {
  const response = await graphqlClient.query({
    query: NewsEntryDocument,
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });
  return response.data.newsEntry;
};

const save = async (news: NewsInput) => {
  const response = await graphqlClient.query({
    query: SaveNewsDocument,
    variables: {
      news,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.news?.save?.success) {
    return response;
  }

  throw new Error(`Saving the news is not successfull`);
};

const deleteItem = async (id: string) => {
  const response = await graphqlClient.query({
    query: DeleteNewsDocument,
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.news?.delete?.success) {
    return response;
  }

  throw new Error(`Deleting the news is not successfull`);
};

export default {
  getNews,
  getNewsDetail,
  deleteItem,
  save,
};
