import { defineAsyncComponent, type Component } from "vue";
import { type IModuleTab } from "./interfaces";

export const TIME_SPLIT_INTERVAL = 15;

export const SCREEN_GRID_TEMPLATES = [
  {
    name: "2A",
    config: {
      gridSize: 4,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/3",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/3",
        },
      ],
    },
  },
  {
    name: "2B",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/3",
          colSpan: "1/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/4",
        },
      ],
    },
  },
  {
    name: "3A",
    config: {
      gridSize: 4,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/3",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/2",
        },
        {
          rowSpan: "2/3",
          colSpan: "2/3",
        },
      ],
    },
  },
  {
    name: "3B",
    config: {
      gridSize: 4,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/2",
        },
        {
          rowSpan: "1/2",
          colSpan: "2/3",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/3",
        },
      ],
    },
  },
  {
    name: "3C",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/4",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/4",
        },
      ],
    },
  },
  {
    name: "4A",
    config: {
      gridSize: 4,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/2",
        },
        {
          rowSpan: "1/2",
          colSpan: "2/3",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/2",
        },
        {
          rowSpan: "2/3",
          colSpan: "2/3",
        },
      ],
    },
  },
  {
    name: "4B",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/3",
          colSpan: "1/3",
        },
        {
          rowSpan: "1/2",
          colSpan: "3/4",
        },
        {
          rowSpan: "2/3",
          colSpan: "3/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/4",
        },
      ],
    },
  },
  {
    name: "4C",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/3",
        },
        {
          rowSpan: "1/2",
          colSpan: "3/4",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/4",
        },
      ],
    },
  },
  {
    name: "4D",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/4",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/3",
        },
        {
          rowSpan: "3/4",
          colSpan: "3/4",
        },
      ],
    },
  },
  {
    name: "5A",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/3",
          colSpan: "1/3",
        },
        {
          rowSpan: "1/2",
          colSpan: "3/4",
        },
        {
          rowSpan: "2/3",
          colSpan: "3/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "3/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/3",
        },
      ],
    },
  },
  {
    name: "5B",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/3",
        },
        {
          rowSpan: "1/2",
          colSpan: "3/4",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/3",
        },
        {
          rowSpan: "2/3",
          colSpan: "3/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/4",
        },
      ],
    },
  },
  {
    name: "5C",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/3",
        },
        {
          rowSpan: "1/2",
          colSpan: "3/4",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/3",
        },
        {
          rowSpan: "3/4",
          colSpan: "3/4",
        },
      ],
    },
  },
  {
    name: "5D",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/4",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/3",
        },
        {
          rowSpan: "2/3",
          colSpan: "3/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/3",
        },
        {
          rowSpan: "3/4",
          colSpan: "3/4",
        },
      ],
    },
  },
  {
    name: "6A",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/3",
        },
        {
          rowSpan: "1/2",
          colSpan: "3/4",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/3",
        },
        {
          rowSpan: "2/3",
          colSpan: "3/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/3",
        },
        {
          rowSpan: "3/3",
          colSpan: "3/4",
        },
      ],
    },
  },
  {
    name: "6B",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/3",
          colSpan: "1/3",
        },
        {
          rowSpan: "1/2",
          colSpan: "3/4",
        },
        {
          rowSpan: "2/3",
          colSpan: "3/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/2",
        },
        {
          rowSpan: "3/4",
          colSpan: "2/3",
        },
        {
          rowSpan: "3/4",
          colSpan: "3/4",
        },
      ],
    },
  },
  {
    name: "6C",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/3",
        },
        {
          rowSpan: "1/2",
          colSpan: "3/4",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/2",
        },
        {
          rowSpan: "3/4",
          colSpan: "2/3",
        },
        {
          rowSpan: "3/4",
          colSpan: "3/4",
        },
      ],
    },
  },
  {
    name: "6D",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/4",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/2",
        },
        {
          rowSpan: "2/3",
          colSpan: "2/3",
        },
        {
          rowSpan: "2/3",
          colSpan: "3/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/3",
        },
        {
          rowSpan: "3/4",
          colSpan: "3/4",
        },
      ],
    },
  },
  {
    name: "7A",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/3",
        },
        {
          rowSpan: "1/2",
          colSpan: "3/4",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/3",
        },
        {
          rowSpan: "2/3",
          colSpan: "3/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/2",
        },
        {
          rowSpan: "3/4",
          colSpan: "2/3",
        },
        {
          rowSpan: "3/4",
          colSpan: "3/4",
        },
      ],
    },
  },
  {
    name: "7B",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/3",
        },
        {
          rowSpan: "1/2",
          colSpan: "3/4",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/2",
        },
        {
          rowSpan: "2/3",
          colSpan: "2/3",
        },
        {
          rowSpan: "2/3",
          colSpan: "3/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/3",
        },
        {
          rowSpan: "3/4",
          colSpan: "3/4",
        },
      ],
    },
  },
  {
    name: "7C",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/4",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/2",
        },
        {
          rowSpan: "2/3",
          colSpan: "2/3",
        },
        {
          rowSpan: "2/3",
          colSpan: "3/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/2",
        },
        {
          rowSpan: "3/4",
          colSpan: "2/3",
        },
        {
          rowSpan: "3/4",
          colSpan: "3/4",
        },
      ],
    },
  },
  {
    name: "8A",
    config: {
      gridSize: 9,
      modules: [
        {
          rowSpan: "1/2",
          colSpan: "1/3",
        },
        {
          rowSpan: "1/2",
          colSpan: "3/4",
        },
        {
          rowSpan: "2/3",
          colSpan: "1/2",
        },
        {
          rowSpan: "2/3",
          colSpan: "2/3",
        },
        {
          rowSpan: "2/3",
          colSpan: "3/4",
        },
        {
          rowSpan: "3/4",
          colSpan: "1/2",
        },
        {
          rowSpan: "3/4",
          colSpan: "2/3",
        },
        {
          rowSpan: "3/4",
          colSpan: "3/4",
        },
      ],
    },
  },
];

export const HOME_SCREEN_MODULES = [
  {
    type: "ACTIVITIES",
    color: "#381466",
    id: 5,
  },
  {
    type: "BOOKING",
    color: "#ADC301",
    id: 3,
  },
  {
    type: "EXTERNAL_ACTIVITIES",
    color: "#591EA3",
    id: 10,
  },
  {
    type: "GALLERY",
    color: "#622A67",
    id: 6,
  },
  {
    type: "JOURNEY_PLANNER",
    color: "#363636",
    id: 11,
  },
  {
    type: "LATER_WORK_SCHEDULE",
    color: "#008C93",
    id: 99,
  },
  {
    type: "MEAL_PLAN",
    color: "#0080DC",
    id: 4,
  },
  {
    type: "MEETINGS",
    color: "#932C5F",
    id: 9,
  },
  {
    type: "NEWS",
    color: "#EA4400",
    id: 7,
  },
  {
    type: "OUR_HOME",
    color: "#58A202",
    id: 1,
  },
  {
    type: "SIMPLE_WORK_SCHEDULE",
    color: "#37A2A7",
    id: 12,
  },
  {
    type: "SLIDESHOW",
    color: "#622A67",
    id: 14,
  },
  {
    type: "WAYFINDER",
    color: "#37A2A7",
    id: 13,
  },
  {
    type: "WEATHER",
    color: "#363636",
    id: 8,
  },
  {
    type: "WORK_SCHEDULE",
    color: "#37A2A7",
    id: 2,
  },
];

export const FOOTER_MODULES = [
  {
    type: "ACTIVITIES",
    color: "#381466",
    id: 5,
  },
  {
    type: "BOOKING",
    color: "#ADC301",
    id: 3,
  },
  {
    type: "EXTERNAL_ACTIVITIES",
    color: "#591EA3",
    id: 10,
  },
  {
    type: "GALLERY",
    color: "#622A67",
    id: 6,
  },
  {
    type: "MEAL_PLAN",
    color: "#0080DC",
    id: 4,
  },
  {
    type: "MEETINGS",
    color: "#932C5F",
    id: 9,
  },
  {
    type: "NEWS",
    color: "#EA4400",
    id: 7,
  },
  {
    type: "OUR_HOME",
    color: "#58A202",
    id: 1,
  },
  {
    type: "SIMPLE_WORK_SCHEDULE",
    color: "#37A2A7",
    id: 12,
  },
  {
    type: "WORK_SCHEDULE",
    color: "#37A2A7",
    id: 2,
  },
];

export const APP_MODULES = [
  {
    type: "ACTIVITY",
    color: "#381466",
  },
  {
    type: "BOOKING",
    color: "#ADC301",
  },
  {
    type: "GALLERY",
    color: "#622A67",
  },
  {
    type: "LIBRARY",
    color: "#004D84",
  },
  {
    type: "MEAL",
    color: "#0080DC",
  },
  {
    type: "MEETING",
    color: "#932C5F",
  },
  {
    type: "NEWS",
    color: "#EA4400",
  },
  {
    type: "WORK_SCHEDULE",
    color: "#37A2A7",
  },
  {
    type: "PROFILES",
    color: "#468202",
  },
  {
    type: "GROUPS",
    color: "#00434E",
  },
  {
    type: "MY_DAY",
    color: "#000",
  },
  {
    type: "WHATS_HAPPENING",
    color: "#000",
  },
];

export const BREAKPOINTS = {
  mobile: 0,
  tablet: 620,
  laptop: 940,
  desktop: 1200,
  "big-desktop": 1400,
};

export const MOBILE_NAV_HEIGHT = "72px";

export const NEWS_VIEW_TABS: IModuleTab[] = [
  { value: "main", title: "global.master_data", isRequired: true },
  { value: "pictures", title: "global.images", isRequired: true },
  // { value: "pdf", title: "PDF" }, // Not on the IBG Basis
  // { value: "video", title: "Video" }, // Not on the IBG Basis
];

export const NEWS_TAB_MAP: Record<string, Component> = {
  main: defineAsyncComponent(() => import("./views/news/MainTab.vue")),
  pictures: defineAsyncComponent(() => import("./views/news/PicturesTab.vue")),
  pdf: defineAsyncComponent(() => import("./views/news/PDFTab.vue")),
  video: defineAsyncComponent(() => import("./views/news/VideoTab.vue")),
};

export const ACTIVITIES_VIEW_TABS: IModuleTab[] = [
  { value: "main", title: "global.master_data", isRequired: true },
  { value: "pictures", title: "global.images", isRequired: true },
  // { value: "pdf", title: "PDF" }, // Not on the IBG Basis
  { value: "video", title: "Video", managerType: "IBG_MANAGER" },
  { value: "pdf", title: "PDF", managerType: "IBG_MANAGER" },
  // { value: "video", title: "Video" }, // Not on the IBG Basis
  // { value: "participants", title: "Deltagere" }, // Not on the IBG Basis
  {
    value: "notifications",
    title: "global.notifications",
    managerType: "IBG_MANAGER",
  },
];

export const ACTIVITIES_TAB_MAP: Record<string, Component> = {
  main: defineAsyncComponent(() => import("./views/activities/MainTab.vue")),
  pictures: defineAsyncComponent(
    () => import("./views/activities/PicturesTab.vue"),
  ),
  video: defineAsyncComponent(() => import("./views/activities/VideoTab.vue")),
  pdf: defineAsyncComponent(() => import("./views/activities/PDFTab.vue")),
  notifications: defineAsyncComponent(
    () => import("./views/activities/NotificationsTab.vue"),
  ),
};

export const EMPLOYEES_VIEW_TABS: IModuleTab[] = [
  { value: "main", title: "global.master_data", isRequired: true },
  { value: "pictures", title: "global.images", isRequired: true },
  { value: "security", title: "global.security", isRequired: true },
];

export const EMPLOYEES_TAB_MAP: Record<string, Component> = {
  main: defineAsyncComponent(() => import("./views/employees/MainTab.vue")),
  pictures: defineAsyncComponent(
    () => import("./views/employees/PicturesTab.vue"),
  ),
  security: defineAsyncComponent(
    () => import("./views/employees/SecurityTab.vue"),
  ),
};

export const NEW_EMPLOYEES_VIEW_TABS = [
  { value: "main", title: "global.master_data", isRequired: true },
  { value: "pictures", title: "global.images", isRequired: true },
  { value: "login", title: "global.security", isRequired: true },
];

export const NEW_EMPLOYEES_TAB_MAP: Record<string, Component> = {
  main: defineAsyncComponent(() => import("./views/employees/MainTab.vue")),
  pictures: defineAsyncComponent(
    () => import("./views/employees/PicturesTab.vue"),
  ),
  login: defineAsyncComponent(
    () => import("./views/employees/LoginInformationsTab.vue"),
  ),
};

export const KEYCLOAK_URL = import.meta.env.VITE_KEYCLOAK_URL;
export const KEYCLOAK_REALM = import.meta.env.VITE_KEYCLOAK_REALM;
export const KEYCLOAK_CLIENT_ID = import.meta.env.VITE_KEYCLOAK_CLIENT_ID;
export const KEYCLOAK_REDIRECT_URI = `${window.location.origin}/callback`;
export const APP_API_URL = import.meta.env.VITE_APP_API_URL;
export const MANAGER_TYPE = import.meta.env.VITE_MANAGER_TYPE || "IBG_MANAGER";

// All storage keys
export const STORAGE_AUTH_MODULE = "auth.module";
