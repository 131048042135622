/**
 * These are the possible keys to use localStorage.
 */
export enum LocalStorageKeys {
  Modules = "modules",
  CustomFilter = "customFilter",
  PlannerFilters = "plannerFilters",
  DefaultPlannerView = "default-planner-view",
  LoopbackAccessTokenId = "$LoopBack$accessTokenId",
}

export enum FileAcceptTypes {
  OnlyPDF = ".pdf",
  OnlyImage = "image/*",
  OnlyDocument = ".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ImageOrDocument = ".odp,.ods,.odt,.doc,.dot,.png,.pdf,.ppt,.rtf,.xls,.xml,.gif,.bmp,.pps,.txt,.msg,.jpg,.jpeg,.docx,.dotx,.docm,.dotm,.xlsx,.xltx,.xlsm,.xltm,.xlsb,.pptx",
}

export enum FileSizes {
  Max5MB = 5 * 1024 * 1024, // 5 MB in bytes
  Max10MB = 10 * 1024 * 1024, // 10 MB in bytes
  Max15MB = 15 * 1024 * 1024, // 15 MB in bytes
  Max50MB = 50 * 1024 * 1024, // 50 MB in bytes
  Max100MB = 100 * 1024 * 1024, // 100 MB in bytes
}
