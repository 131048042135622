import { graphqlClient } from "@/apollo";
import {
  type NotificationSettingsInput,
  SaveActivityNotificationDocumentDocument,
} from "@/graphql/generated-types";

const save = async (settings: NotificationSettingsInput) => {
  try {
    const response = await graphqlClient.query({
      query: SaveActivityNotificationDocumentDocument,
      variables: {
        settings,
      },
      fetchPolicy: "no-cache",
    });

    if (!response.data.activity?.saveNotificationSettings?.success) {
      throw new Error(`Saving the activity notification is not successful`);
    }

    return response;
  } catch (error) {
    throw new Error(`Saving the activities is not successful ${error}`);
  }
};

export default {
  save,
};
