import { graphqlClient } from "@/apollo";
import {
  GetActivitiesByRangeDocument,
  type GetActivitiesByRangeQueryVariables,
  SaveActivityDocument,
  DeleteActivityDocument,
  type ActivityInput,
} from "@/graphql/generated-types";

type VisibilityOption = "visibility-on" | "visibility-off";

const getActivities = async (
  dateFrom: string,
  dateTo: string,
  visibility: VisibilityOption,
) => {
  const variables: GetActivitiesByRangeQueryVariables = {
    dateFrom,
    dateTo,
    filter: {
      departments: [],
      groups: [],
      institutions: [],
    },
    visibility: visibility === "visibility-on",
  };

  const response = await graphqlClient.query({
    query: GetActivitiesByRangeDocument,
    variables,
    fetchPolicy: "no-cache",
  });

  if (response.data.activity?.getActivitiesByFilters) {
    return response.data.activity.getActivitiesByFilters;
  }

  throw new Error("Query error");
};

const save = async (activity: ActivityInput) => {
  try {
    const response = await graphqlClient.query({
      query: SaveActivityDocument,
      variables: {
        activity,
      },
      fetchPolicy: "no-cache",
    });

    if (!response.data.activity?.save?.success) {
      throw new Error("Error saving the activity");
    }

    return response;
  } catch (error) {
    throw new Error(`Saving the activities is not successfull ${error}`);
  }
};

const deleteItem = async (id: string) => {
  const response = await graphqlClient.query({
    query: DeleteActivityDocument,
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.activity?.delete?.success) {
    return response;
  }

  throw new Error(`Deleting the activity is not successfull.`);
};

export default {
  getActivities,
  save,
  deleteItem,
};
