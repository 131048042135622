import { graphqlClient } from "@/apollo";
import {
  type ResourceInput,
  SaveResourceDocument,
} from "@/graphql/generated-types";

const save = async (resource: ResourceInput) => {
  const response = await graphqlClient.query({
    query: SaveResourceDocument,
    variables: {
      resource,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.resource?.save?.success) {
    return response.data.resource.save.id;
  }

  throw new Error("Resource save fail.");
};

export default {
  save,
};
