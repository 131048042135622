import { defineStore } from "pinia";
import {
  KEYCLOAK_URL,
  KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REDIRECT_URI,
} from "@/consts";
import Keycloak from "keycloak-js";
import { STORAGE_AUTH_MODULE } from "../consts";
import api from "../api";
import { type Employee } from "@/graphql/generated-types";

interface IAuthState {
  login: boolean;
  token?: string | null;
  idToken?: string | null;
  id?: string | null;
  profile?: Employee | null;
}

const DEFAULT_STATE = {
  login: false,
  token: null,
  idToken: null,
  id: null,
  profile: null,
};

export const useAuthStore = defineStore("auth", {
  state: (): IAuthState => ({
    ...DEFAULT_STATE,
  }),
  getters: {
    institutionId: (state: any): string => state.profile?.institution?.id || "",
  },
  actions: {
    async setLogin(token: string, id: string, idToken: string) {
      this.login = true;
      this.token = token;
      this.id = id;
      this.idToken = idToken;
      this.profile = await api.user.getSelf();
      this.updateToStorage();
    },
    async logout() {
      const idToken = this.idToken;
      this.login = false;
      this.token = null;
      this.idToken = null;
      this.id = null;
      this.updateToStorage();

      const keycloak = new Keycloak({
        url: KEYCLOAK_URL,
        realm: KEYCLOAK_REALM,
        clientId: KEYCLOAK_CLIENT_ID,
      });

      await keycloak.init({ idToken: idToken || undefined });

      // Let's create a logout url
      let url = keycloak.createLogoutUrl();
      // We need to add id_token_hint value
      // https://github.com/keycloak/keycloak/discussions/12183
      url += "&id_token_hint=" + idToken;
      // Redirect to URL
      window.location.replace(url);
    },
    updateToStorage() {
      const data = JSON.stringify(this.$state);
      sessionStorage.setItem(STORAGE_AUTH_MODULE, data);
    },
    async init() {
      const data = sessionStorage.getItem(STORAGE_AUTH_MODULE);
      if (data) {
        const state: IAuthState = {
          ...DEFAULT_STATE,
          ...JSON.parse(data),
        };
        // Let's update the current state
        this.$patch(state);

        // We might need to fetch the latest profile data because of default
        // values. To be able to fetcj, we had to set the state first. Because
        // API requests need token.
        if (state.login && state.token) {
          state.profile = await api.user.getSelf();
        }

        // Let's update the state one more time with the new profile data
        this.$patch(state);
      }
    },
    async redirectLogin() {
      this.init();

      // We don't need to redirect to keycloak if login is successful
      if (this.login) {
        return;
      }

      const keycloak = new Keycloak({
        url: KEYCLOAK_URL,
        realm: KEYCLOAK_REALM,
        clientId: KEYCLOAK_CLIENT_ID,
      });

      await keycloak.init({
        redirectUri: KEYCLOAK_REDIRECT_URI,
        responseMode: "query",
      });

      await keycloak.login({
        scope: "openid offline_access profile",
      });
    },
  },
});
